import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ListGroup from 'react-bootstrap/ListGroup'
import SellRecipe from '../svg-icons/sell-recipe'
import SellChefHat from '../svg-icons/sell-chef-hat'
import SellStove from '../svg-icons/sell-stove'
import CulinaryButtonLink from '../common/button-culinary-guide'

const CutsCards = props => {
  const cuts = useStaticQuery(graphql`
    {
      allWpCut {
        nodes {
          content
          title
          cutsFields {
            cuttingBoardImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 608, placeholder: BLURRED, layout: CONSTRAINED)
                }
              }
            }
            downloadsLinks {
              icon
              text
              downloadType
              link
              file {
                uri
                slug
                localFile {
                  extension
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section id="cutsCards" className="py-2 py-md-3 py-xl-4 bg-light">
      <Container>
        <h2 className="text-center text-primary fw-bold mb-1 mb-md-2">Explore our cuts</h2>
        <Row className="g-12 g-sm-1">
          {cuts.allWpCut.nodes.map((node, index) => {
            const image = getImage(node.cutsFields.cuttingBoardImage.localFile.childImageSharp.gatsbyImageData)
            const alt = `Meati ${node.title} cutting board image`
            const wrapperClass = 'rounded-4 mb-1'
            const imgClass = 'rounded-4'

            return (
              <Col key={`cut-${index}`} sm="6">
                <div className="rounded-4 bg-white p-12 p-md-1 h-100 cut-card d-flex flex-column justify-content-between">
                  <div>
                    <GatsbyImage image={image} alt={alt} className={wrapperClass} imgClassName={imgClass} />
                    <h3>{node.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: node.content }} />
                  </div>

                  {node.cutsFields.downloadsLinks && (
                    <ListGroup variant="flush">
                      {node.cutsFields.downloadsLinks.map((n, i) => {
                        const href =
                          n.downloadType === 'link' && n.link
                            ? n.link
                            : n.downloadType === 'file' && n.file
                            ? `/files/${n.file.slug}.${n.file.localFile.extension}`
                            : '#'
                        if (n.text === 'Download sell sheet') {
                          // we need to make this change in WP
                          return null
                        }
                        return (
                          <ListGroup.Item key={`cut-links-${i}`} className="px-0">
                            <a href={href} target="_blank" rel="noopener noreferrer" className="text-dark">
                              <h4 className="h6 d-flex align-items-center mb-0">
                                <i className="me-1 d-flex justify-content-center sell-icon-wrapper flex-shrink-0">
                                  {n.icon === 'stove' ? <SellStove /> : <SellChefHat />}
                                </i>
                                {n.text}
                              </h4>
                            </a>
                          </ListGroup.Item>
                        )
                      })}
                    </ListGroup>
                  )}
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

export default CutsCards
