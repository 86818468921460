import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ButtonLink from '../common/button-link'

const AllStarCast = props => {
  const partners = useStaticQuery(graphql`
    query {
      allPartnersYaml(filter: { logo_white: { id: { ne: null } } }) {
        nodes {
          name
          logo_white {
            childImageSharp {
              gatsbyImageData(width: 500, placeholder: BLURRED, formats: NO_CHANGE, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <section id="allStarCast" className="py-2 py-md-3 py-lg-4 bg-dark text-white">
      <Container className="px-3 px-xl-6">
        <h2 className="fst-italic mb-2 mb-lg-3 text-center">Join Our All-Star Cast</h2>
        <Row className="g-2 g-xl-3 justify-content-center" id="partnerLogos">
          {partners.allPartnersYaml.nodes.map((node, index) => (
            <div key={`logo-${index}`} className="align-items-center d-flex col-4 col-md-auto">
              <GatsbyImage
                image={getImage(node.logo_white)}
                alt={`Meati partner - ${node.name} logo`}
                className="mx-md-12"
              />
            </div>
          ))}
        </Row>
        <div className="mt-3 mt-lg-4 d-flex justify-content-center">
          <ButtonLink btnClass="btn-lg btn-warning" btnLink="/where-is-meati" btnText="See more" />
        </div>
      </Container>
    </section>
  )
}

export default AllStarCast
