import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import WebRestaurantLogo from '../../images/sell/webrestaurant-logo.svg'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { Alert, Button, Form } from 'react-bootstrap'

const SellForm = () => {
  const [validated, setValidated] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [fnameValue, setFnameValue] = useState('')
  const [lnameValue, setLnameValue] = useState('')
  const [establismentValue, setEstablismentValue] = useState('')
  const [phoneNumberValue, setPhoneNumberValue] = useState('')
  const [triedMeatiValue, setTriedMeatiValue] = useState('no')
  const [distributorValue, setDistributorValue] = useState('')
  const [storeLocationValue, setStoreLocationValue] = useState('')
  const [numLocationValue, setNumLocationValue] = useState('')
  const [storeNameValue, setStoreNameValue] = useState('')
  const [msgValue, setMsgValue] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [checkBoxes, setCheckBoxes] = useState([])

  const setRadio = e => {
    let newArray = [...checkBoxes]
    const checkboxInfo = { value: e.value, label: e.labels[0].innerText }

    if (e.checked) {
      newArray = [...newArray, checkboxInfo]
    } else {
      newArray = newArray.filter(item => item.value !== checkboxInfo.value)
    }
    setCheckBoxes(newArray)
  }

  const processForm = e => {
    const checkBoxString = checkBoxes.map(checkbox => checkbox.label).join(', ')

    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget

    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(true)
    setFormDisabled(true)

    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      headers: {
        accept: 'application/json, text/plain, */*',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        g: 'YdyfZM',
        $fields:
          'first_name, last_name, Phone, Sell - type of business, Sell - location, Sell - distributor, Sell - product(s) of interest, Sell - store name, Sell - number of locations, $source',
        email: emailValue,
        first_name: fnameValue,
        last_name: lnameValue,
        phone: phoneNumberValue,
        tried_meati: triedMeatiValue,
        'Sell - type of business': establismentValue,
        'Sell - location': storeLocationValue,
        'Sell - distributor': distributorValue,
        'Sell - product(s) of interest': [checkBoxes],
        'Sell - store name': storeNameValue,
        'Sell - number of locations': numLocationValue,
        $source: 'Sell Form',
      }),
      method: 'POST',
    })
      .then(() => {
        setShowAlert(true)
      })
      .catch(err => {
        console.error(err)
      })

    if (window.klaviyo) {
      window.klaviyo.push(['track', 'Requested Sales Contact'])
    }

    // Zendesk

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const name = `${fnameValue} ${lnameValue}`

    const raw = JSON.stringify({
      request: {
        requester: {
          name,
          email: emailValue,
        },
        subject: `Sell form submission from ${name}`,
        comment: {
          body: msgValue,
        },
        custom_fields: [
          { id: 6358150629261, value: checkBoxes },
          { id: 6358180741261, value: storeLocationValue },
          { id: 8831279798541, value: phoneNumberValue },
          { id: 8831461574541, value: establismentValue },
          { id: 8831516360205, value: distributorValue },
          { id: 8885635780877, value: numLocationValue },
          { id: 8891870517773, value: storeNameValue },
        ],
      },
    })

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    fetch('https://meatifoods.zendesk.com/api/v2/requests.json', requestOptions)
      .then(response => {
        response.text()
      })
      .then(result => {
        // console.log(result)
      })
      .catch(error => {
        console.log('error', error)
      })

    // Gorgias

    const formatMetaDataPlainText = metaData => {
      let plainTextString = '-----\nAdditional Information:\n'

      for (const key in metaData) {
        if (metaData.hasOwnProperty(key)) {
          plainTextString += `- ${key}: ${metaData[key]}\n`
        }
      }

      return plainTextString
    }

    const metaData = {
      'First Name': fnameValue,
      'Last Name': lnameValue,
      Email: emailValue,
      Phone: phoneNumberValue,
      'Store/Business Name': storeNameValue,
      'Type of Business': establismentValue,
      'Store Location': storeLocationValue,
      'Number of Locations': numLocationValue,
      Distributor: distributorValue,
      'Products of Interest': checkBoxString,
      'Have you tried meati before?': triedMeatiValue,
    }

    const metaDataPlainText = formatMetaDataPlainText(metaData)
    const messageBodyPlainText = `${msgValue}\n\n${metaDataPlainText}`

    fetch('/.netlify/functions/gorgias-handler', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: emailValue,
        firstName: fnameValue,
        lastName: lnameValue,
        message: messageBodyPlainText,
        subject: `Sell form submission from ${fnameValue} ${lnameValue}`,
        meta: metaData,
        phone: phoneNumberValue,
        team: 1593,
      }),
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        setShowAlert(true)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  return (
    <section className="bg-info py-2 text-white">
      <Container>
        <Row className="g-lg-4">
          <Col md="5" xl="6" className="mb-1 pt-md-2 pt-xl-4">
            <div className="px-xl-5 px-xxl-8">
              <h2>Get Meati on Your Menu</h2>
              <p className="lead mb-xl-2">
                Fill out the form to chat with us about partnership. We’ll get back to you ASAP!
              </p>
              <a
                href="https://www.webstaurantstore.com/vendor/meati.html"
                role="button"
                className="btn btn-lg btn-outline-warning py-1 text-white d-flex flex-column align-items-center"
              >
                Ready for your first case? Order now!
                <img src={WebRestaurantLogo} alt="Webrestaurantstore Logo" className="mt-1 mx-2 mx-xl-4" />
              </a>
            </div>
          </Col>
          <Col md="7" xl="6">
            <p className="fst-italic text-white">
              <small>All fields required</small>
            </p>
            <Form
              id="sellForm"
              noValidate
              validated={validated}
              onSubmit={processForm}
              data-ad-element-type="sell meati form"
              data-ad-text="Get meati"
              data-ad-button-location="body"
            >
              <Form.Group className="mb-1" controlId="formName">
                <Row className="g-1">
                  <Col sm>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      required
                      onChange={e => setFnameValue(e.target.value)}
                      className="form-control-round"
                    />
                    <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                      Please enter your first name
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      required
                      onChange={e => setLnameValue(e.target.value)}
                      className="form-control-round"
                    />
                    <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                      Please enter your last name
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-1">
                <Form.Control
                  required
                  type="email"
                  placeholder="Email"
                  value={emailValue}
                  pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
                  onChange={e => setEmailValue(e.target.value)}
                  className="form-control-round"
                />
                <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                  Please enter a valid email address
                </Form.Control.Feedback>
              </Form.Group>

              <PhoneInput
                className="mb-1"
                defaultCountry="US"
                placeholder="Phone number"
                value={phoneNumberValue}
                onChange={setPhoneNumberValue}
                rules={{ required: true }}
              />

              <Row className="gx-1">
                <Col sm="6" md="12" lg="6">
                  <Form.Group controlId="formStoreName" className="mb-1">
                    <Form.Control
                      type="text"
                      placeholder="Store/business name"
                      value={storeNameValue}
                      onChange={e => setStoreNameValue(e.target.value)}
                      className="form-control-round"
                      required
                    />
                  </Form.Group>
                  <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                    Please enter your store or business name
                  </Form.Control.Feedback>
                </Col>

                <Col sm="6" md="12" lg="6">
                  <Form.Group controlId="forEstablishment" className="mb-1">
                    <Form.Select
                      required
                      aria-label="Type of business"
                      className="form-control-round"
                      onChange={e => setEstablismentValue(e.target.value)}
                    >
                      <option value="not specified">Type of business</option>
                      <option value="retailer">Retailer</option>
                      <option value="food operator">Food operator</option>
                      <option value="broker">Broker</option>
                      <option value="distributor">Distributor</option>
                      <option value="other">Other</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                      Please select your business type
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm="6" md="12" lg="6">
                  <Form.Group controlId="formLocation" className="mb-1">
                    <Form.Control
                      type="text"
                      placeholder="Store location"
                      required
                      onChange={e => setStoreLocationValue(e.target.value)}
                      className="form-control-round"
                    />
                    <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                      Please enter your store location
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm="6" md="12" lg="6">
                  <Form.Group controlId="formNumLocations" className="mb-1">
                    <Form.Control
                      type="text"
                      placeholder="# of locations"
                      value={numLocationValue}
                      onChange={e => setNumLocationValue(e.target.value)}
                      className="form-control-round"
                      required
                    />
                    <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                      Please enter the number of locations
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="forDistributor" className="mb-1">
                <Form.Control
                  type="text"
                  placeholder="Which distributor do you use?"
                  value={distributorValue}
                  onChange={e => setDistributorValue(e.target.value)}
                  className="form-control-round"
                  required
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid" className="fst-italic fc-feedback text-white">
                Please enter your distributor
              </Form.Control.Feedback>

              <fieldset>
                <Form.Group as={Row} className="mb-1 fs-5 text-white">
                  <Form.Label as="legend" column sm={12} className="h6">
                    What are you reaching out about?
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Check
                      type="checkbox"
                      label="Classic Steak"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios1"
                      value="classic_steak"
                      onChange={e => setRadio(e.target)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Crispy Cutlet"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios2"
                      value="crispy_cutlet"
                      onChange={e => setRadio(e.target)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Classic Cutlet"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios3"
                      value="classic_cutlet"
                      onChange={e => setRadio(e.target)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Carne Asada"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios4"
                      value="carne_asada"
                      onChange={e => setRadio(e.target)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Breakfast Patty Original"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios4"
                      value="brealfast_patty_original"
                      onChange={e => setRadio(e.target)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Breakfast Patty Maple"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios4"
                      value="breakfast_patty_maple"
                      onChange={e => setRadio(e.target)}
                    />
                  </Col>
                </Form.Group>
              </fieldset>

              <fieldset>
                <Form.Group as={Row} className="mb-1 fs-5 text-white">
                  <Form.Label as="legend" column sm={12} className="h6">
                    Have you tried meati™ before?
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Check
                      type="checkbox"
                      label="Yes, I have tried meati™ before"
                      name="formStoreNameValue"
                      id="formStoreNameValue1"
                      defaultValue="no"
                      value={triedMeatiValue}
                      onChange={e => {
                        setTriedMeatiValue(e.target.value === 'yes' ? 'no' : 'yes')
                      }}
                    />
                  </Col>
                </Form.Group>
              </fieldset>

              <Form.Group className="mb-1" controlId="formMessage">
                <Form.Control
                  as="textarea"
                  rows={6}
                  placeholder="Message"
                  onChange={e => setMsgValue(e.target.value)}
                  className="form-control-round"
                />
              </Form.Group>

              <Button
                variant="warning"
                className="btn-lg"
                type="submit"
                disabled={formDisabled}
                data-ad-element-type="sell meati form submit"
                data-ad-text="Sign Up"
                data-ad-button-location="hero banner"
                data-ad-button-id="version 1"
              >
                Submit
              </Button>
            </Form>
            <Alert variant="success" className="mt-1" show={showAlert}>
              Thanks! We'll be in touch.
            </Alert>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SellForm
