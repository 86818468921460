import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faExternalLink, faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import Layout from '../components/layout'
import Seo from '../components/seo'

import FDSVFeatures from '../components/common/fdsv-features'
import PLNTCaseStudy from '../components/common/plnt-case-study'

import SellCTA from '../components/sections/sell-cta'
import SellForm from '../components/common/sell-form'
import IfYouBuildIt from '../components/sections/if-you-build-it'
import CutsCards from '../components/sections/cuts-cards'
import AllStarCast from '../components/sections/all-star-cast'
import ScrollingImages from '../components/sections/scrolling-images'
import Awards from '../components/sections/awards'

const SellPage = () => {
  return (
    <Layout className="sell-page">
      <Seo title="Sell Meati™" />
      <header className="bg-primary py-1 py-sm-2 pb-md-0 pt-xxl-3">
        <Container>
          <h1 className="fw-bolder text-white z-1 position-relative mx-1 mx-md-0 mb-md-2">
            Your Menu is Missing Something
          </h1>
          <Row className="z-0 gx-md-2">
            <Col md="6" className="order-md-2 position-relative">
              <StaticImage
                src="../images/sell/meati-lillies.jpg"
                quality={95}
                placeholder="blurred"
                alt="Meati Lillies"
                layout="constrained"
                className="rounded-5 fill-parent"
                imgClassName="rounded-5"
                width={800}
              />
            </Col>
            <Col md="6" className="order-md-1">
              <p className="text-white fs-4 mb-md-2">
                Add deliciously juicy, ultra-tender meati™ cuts to your menu or shelves. Animal-free, super versatile,
                and an excellent source of complete protein without soy, gluten, or wheat. Your customers — and their
                tastebuds — will thank you!
              </p>
              <div className="d-flex flex-wrap flex-column align-items-start mb-md-2 gap-1">
                <a
                  href="#sellForm"
                  role="button"
                  className="btn btn-lg btn-dark"
                  data-ad-element-type="sell CTA"
                  data-ad-text="Partner with us"
                  data-ad-button-location="body"
                  data-ad-button-id="version 1"
                >
                  <FontAwesomeIcon className="me-12" icon={faEnvelope} />
                  Partner with us
                </a>
                <a
                  href="/files/meati-full-range-sell-sheet.pdf"
                  target="_blank"
                  role="button"
                  className="btn btn-lg btn-outline-white"
                  data-ad-element-type="retail sell sheet"
                  data-ad-text="View our retail sell sheet"
                  data-ad-button-location="body"
                  data-ad-button-id="version 1"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon className="me-12" icon={faFilePdf} />
                  View our retail sell sheet
                </a>
                <a
                  href="/files/meati-fdsv-sell-sheet.pdf"
                  target="_blank"
                  role="button"
                  className="btn btn-lg btn-dark"
                  data-ad-element-type="food service sell sheet"
                  data-ad-text="View our food service sell sheet"
                  data-ad-button-location="body"
                  data-ad-button-id="version 1"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon className="me-12" icon={faFilePdf} />
                  View our food service sell sheet
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <FDSVFeatures />
      <SellForm />
      <PLNTCaseStudy />
      <IfYouBuildIt />
      <CutsCards />
      <Awards />
      <AllStarCast />
      <SellCTA />
    </Layout>
  )
}

export default SellPage
